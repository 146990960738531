 <style lang="sass" scoped>
.Echarts
    margin: 40px 0
label
    margin-right: 5px
</style>
<style lang="scss">
.dateDisabledClass {
    span {
        text-decoration: line-through
    }
}
</style>
<template>
    <div class="container">
        <div class="search-bar">
            <SelectPagination
                class="mgt-10"
                v-if="isAuth && idcOptionData.length"
                :selectValue.sync="manufacturerId"
                :option-data="idcOptionData"
                :page-info="pageInfo"
                :loading="loading"
                @selectPageChange="idcPageChange($event)"
                select-type="selectIdc"
                placeholder="请选择 IDC 厂商"                
                defaultValue
            ></SelectPagination>
            <label class="mgt-10">机房名</label>
            <SelectPagination
                class="mgt-10"
                :selectValue.sync="params.roomName"
                :option-data="roomOptionData"
                :page-info="pageInfo"
                :loading="loading"
                @selectPageChange="roomPageChange($event)"
                @selectChange="changeRoom()"
                select-type="selectRoom"
                placeholder="请选择机房"
                filterable
                clearable
                defaultValue
            ></SelectPagination>
            <label class="mgt-10">时间区间</label>
            <el-date-picker
                class="date-picker mgt-10"
                v-model="date"
                @change="changeDate"
                type="daterange"
                value-format="timestamp"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :clearable="false"
                :picker-options="pickerOptions"
            >
            </el-date-picker>
            <div class="mgt-10" style="float: right">
                <el-button @click="getStats()">刷新</el-button>
                <el-button type="primary" @click="downloadExcel()">EXCEL 导出</el-button>
            </div>
        </div>
        <div class="Echarts">
            <div
                id="lineChart"
                v-loading="chartLoading"
                style="height: 400px"
            ></div>
        </div>
        <div v-if="params.roomName">
            <el-table :data="tableData">
                <el-table-column prop="date" label="日期"></el-table-column>
                <el-table-column prop="bandwidth95" label="日95峰值">
                    <template slot-scope="scoped">
                        <span>{{ scoped.row.bandwidth95 | formatbytes }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="price" label="机房单价">
                    <template slot-scope="scoped">
                        <span>{{ scoped.row.price.toFixed(2) }} 元/Mbps/日</span>
                    </template>
                </el-table-column>
                <el-table-column prop="income" label="日收益">
                    <template slot-scope="scoped">
                        <span>{{ scoped.row.income.toFixed(2) }} 元</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import SelectPagination from "@/components/SelectPagination";
import users from "/src/api/user.js";
import room from "/src/api/room.js";
import stats from "@/api/dashbord";
import moment from "moment";
export default {
    components: { SelectPagination },
    data() {
        return {
            loading: true,
            chartLoading: false,
            isAuth: false,
            pageInfo: {
                page: 1,
                size: 10,
            },
            idcOptionData: [],
            roomOptionData: [],
            date: [moment(), moment()],
            params: {
                roomName: "",
                startTime: moment()
                    .startOf("day")
                    .toISOString(),
                endTime: moment().toISOString(),
            },
            manufacturerId: null,
            tableData: [],
            bwUnit: "Gbps",
            flowUnit: "Gbps",
            reqsUnit: "Gbps",
            subm_bw: null,
            subm_bw95: null,
            dateArr: [],
            bandwidth: [],
            flow: [],
            bandwidth95: [],
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
                cellClassName(time) {
                    if (time.getTime() > Date.now()) {
                        return 'dateDisabledClass'
                    }
                }
            },
            lineOption: {
                title: {
                    text: "带宽及请求趋势",
                },
                color: ["#5470c6", "#91cc75"],
                tooltip: {
                    trigger: "axis",
                    formatter: (datas) => {
                        let res = datas[0].name + "<br/>";
                        datas.forEach((item) => {
                            res +=
                                item.seriesName +
                                "：" +
                                item.value.toFixed(3)+
                                " " +
                                (item.seriesName === "带宽"
                                    ? this.bwUnit
                                    : this.reqsUnit) +
                                "<br/>";
                        });
                        return res;
                    },
                },
                legend: {
                    data: ["带宽", "95带宽峰值"],
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        boundaryGap: false,
                        axisLabel: {
                            textStyle: {
                                color: "#747474",
                                // fontFamily: $rootScope.chartFontFamily
                            },
                            formatter: function (value) {
                                return value.toString().substr(11); // format: hh:mm
                            },
                        },
                        data: [],
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        splitNumber: 2,
                        axisLabel: {
                            formatter: (value) => {
                                return `${value} ${this.bwUnit}`;
                            },
                        }
                    },
                ],
                series: [
                    {
                        name: "带宽",
                        type: "line",
                        symbol:'none', // 去掉拐点
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                        },
                        data: [],
                    },
                    {
                        name: "95带宽峰值",
                        type: "line",
                        symbol:'none', // 去掉拐点
                        lineStyle: {
                            width: 1,
                        },
                        data: [],
                    },
                ],
            },
        };
    },
    filters: {
        formatbytes (bytes) {
            if (bytes === 0) return '0 bps'
            const k = 1000
            const sizes = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps']
            const i = Math.floor(Math.log(bytes) / Math.log(k))
            return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i]
        }
    },
    watch: {
        // params: {
        //     handler: function (val) {
        //         this.getStats();
        //         val.roomName && this.getBw95Stats()
        //     },
        //     deep: true,
        // },
        manufacturerId: {
            handler: function (newVal) {
                this.params.roomName = ''
                this.manufacturerId = newVal
                this.getRoom({ manufacturerId: this.manufacturerId });
                this.getStats()
            },
        }
    },
    mounted() {
        this.isAuth =
            JSON.parse(localStorage.getItem("user")).roles.includes("admin");
        if (!this.isAuth) {
            this.manufacturerId = JSON.parse(localStorage.getItem("user")).id
        }
        this.getIDC();
        this.timer = setInterval(() => {
            this.getStats();
        }, 1000 * 300)
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
    methods: {
        changeRoom() {
            this.getStats();
            this.params.roomName && this.getBw95Stats()
        },
        changeDate(val) {
            this.params.startTime = moment(val[0]).toISOString();
            const nowTime = moment(new Date()).toISOString()
            const endTime = moment(val[1]).endOf("day").toISOString()
            const isEndTime = moment(new Date()).valueOf() > moment(val[1]).endOf("day").valueOf()
            this.params.endTime = isEndTime ? endTime : nowTime
            this.getStats();
            this.params.roomName && this.getBw95Stats()
        },
        idcPageChange(page) {
            this.getIDC(page);
        },
        roomPageChange(page) {
            this.getRoom({ page });
        },
        getRoom(params) {
            const roomParams = Object.assign(
                {},
                { size: 10, page: this.pageInfo.page },
                params
            );
            room.getRoom(roomParams).then((data) => {
                this.roomOptionData = data.rooms || [];
                this.pageInfo.total = data.count;
                this.loading = false;
            });
        },
        getIDC(page) {
            const idcParams = {
                page: page || this.pageInfo.page,
                size: 10,
            };
            users.getUserAccount(idcParams).then((data) => {
                this.idcOptionData = data.list || [];
                this.manufacturerId = this.idcOptionData[0].id
                this.pageInfo.total = data.count;
                this.loading = false;
            });
        },
        getStats() {
            this.dateArr = [];
            this.bandwidth = [];
            this.bandwidth95 = [];
            this.chartLoading = true;
            const thisParams = Object.assign({}, this.params);
            if (thisParams.roomName) {
                delete thisParams.manufacturer_id
            } else {
                thisParams.manufacturer_id = this.manufacturerId
            }
            const BWArr = [];
            const BW95Arr = [];
            stats.getStats(thisParams).then((data) => {
                data.stats.forEach((item) => {
                    this.dateArr.push(
                        moment(item.pubtime).format("YYYY-MM-DD HH:mm:ss")
                    );
                    BWArr.push((item.bytes * 8) / 300);
                    BW95Arr.push(data.bandwidth95);
                });
                let _maxBw = Math.max.apply(null, BWArr);
                if (_maxBw > 1000 * 1000 * 1000) {
                    this.bwUnit = "Gbps";
                    this.subm_bw = 1000 * 1000 * 1000;

                    this.reqsUnit = "Gbps";
                    this.subm_bw95 = 1000 * 1000 * 1000;
                } else if (_maxBw > 1000 * 1000) {
                    this.bwUnit = "Mbps";
                    this.subm_bw = 1000 * 1000;

                    this.reqsUnit = "Mbps";
                    this.subm_bw95 = 1000 * 1000;
                } else if (_maxBw > 1000) {
                    this.bwUnit = "Kbps";
                    this.subm_bw = 1000;
                    
                    this.reqsUnit = "Kbps";
                    this.subm_bw95 = 1000;
                } else {
                    this.bwUnit = "bps";
                    this.subm_bw = 1;

                    this.reqsUnit = "bps";
                    this.subm_bw95 = 1;
                }
                data.stats.forEach((item) => {
                    this.bandwidth.push((item.bytes * 8) / 300 / this.subm_bw);
                    this.bandwidth95.push(data.bandwidth95 / this.subm_bw95);
                });
                this.chartLoading = false;
                this.drawCharts();
            });
        },
        formatbytes (bytes) {
            if (bytes === 0) return '0 bps'
            const k = 1000
            const sizes = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps']
            const i = Math.floor(Math.log(bytes) / Math.log(k))
            return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i]
        },
        drawCharts() {
            var lineChart = this.$echarts.init(
                document.getElementById("lineChart")
            );
            //配置图表
            this.lineOption.xAxis[0].data = this.dateArr;
            const bwMax = Math.max(...this.bandwidth)
            this.lineOption.yAxis[0].max = bwMax > 1 ? Math.ceil(bwMax * 1.1) : 1;
            this.lineOption.yAxis[0].interval = this.lineOption.yAxis[0].max / 2;

            this.lineOption.series[0].data = this.bandwidth;
            this.lineOption.series[1].data = this.bandwidth95;

            this._analyseRange();
            lineChart.setOption(this.lineOption);
        },

        _analyseRange() {
            let dateStart = new Date(this.date[0]).getTime();
            let dateEnd = new Date(this.date[1]).getTime();
            let period = parseInt((dateEnd - dateStart) / 86400000) + 1 || 1;
            this.lineOption.xAxis[0]["axisLabel"]["formatter"] = function (
                value
            ) {
                if (period === 1) return value.toString().substr(11);
                // format: hh:mm
                else if (period <= 7 && period > 1)
                    return value.toString().substr(5, 11);
                // format: MM-DD hh:mm
                else if (period > 7) return value.toString().substr(5, 5); // format: MM-DD
            };
        },
        getBw95Stats() {
            const thisParams = Object.assign({}, this.params);
            stats.getBw95Stats(thisParams).then((data) => {
                this.tableData = data.stats
            })
        },
        downloadExcel() {
            const thisParams = Object.assign({}, this.params);
            thisParams.manufacturer_id = this.manufacturerId
            stats.exportData(thisParams).then((res) => {
                const data = res;
                const blob = new Blob([data])
                const fileName = '带宽.xlsx'
                let downloadElement = document.createElement('a');
                let href = window.URL.createObjectURL(blob);
                downloadElement.href = href;
                downloadElement.download = fileName;
                document.body.appendChild(downloadElement);
                downloadElement.click();
                document.body.removeChild(downloadElement);
                window.URL.revokeObjectURL(href);
            }).catch((err) => {
                console.log(err)
            })
        },
    },
};
</script>
