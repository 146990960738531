<style lang="sass" scoped>
.el-scrollbar__wrap
    padding-bottom: 32px
.select-pagination
    background-color: white
    border-radius: 0 0 4px 4px
    border: 1px solid #e5e7ec
    border-top: none
    width: 101%
    position: absolute
    left: -1px
    bottom: -28px
    text-align: center
    box-sizing: border-box
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%)
.select-type
    margin-right: 20px
</style>
<style lang="sass">
.el-scrollbar
    overflow: inherit
.el-scrollbar__wrap
    // padding-bottom: 32px
</style>
<template>
    <el-select
        class="select-type"
        v-model="keyword"
        :filterable="filterable"
        :clearable="clearable"
        :placeholder="placeholder"
        @change="handleChange"
        :loading="loading"
        :size="size"
    >
        <el-option
            class="aaaaa"
            v-for="item in optionData"
            :key="selectType === 'selectIdc' ? item.manufacturer_name : item.id"
            :label="
                selectType === 'selectIdc' ? item.manufacturer_name : item.name
            "
            :value="selectType === 'selectIdc' ? item.id : item.name"
        >
        </el-option>
        <div class="select-pagination">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="pageInfo.page"
                :page-size="pageInfo.size"
                layout=" prev, next"
                :total="pageInfo.total"
            >
            </el-pagination>
        </div>
    </el-select>
</template>

<script>
export default {
    name: "paginationSelect",
    props: {
        selectValue: {
            // eslint-disable-next-line vue/require-prop-type-constructor
            type: String | Number,
            default: null
        },
        selectType: String,
        placeholder: String,
        filterable: Boolean,
        defaultValue: Boolean,
        clearable: Boolean,
        optionData: Array,
        pageInfo: Object,
        loading: Boolean,
        size: String
    },
    data() {
        return {
            keyword: this.selectValue
        };
    },
    watch: {
        selectValue: {
            handler(newVal) {
                this.keyword = newVal
            },
    },
    },
    mounted() {
    },
    methods: {
        handleChange(val) {
            this.$emit("update:selectValue", val);
            this.$emit("selectChange", val);
        },
        //翻页
        handleCurrentChange(val) {
            this.$emit("selectPageChange", val);
        }
    }
};
</script>
