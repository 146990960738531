import $axios from "./index";

const stats = {
    async getStats(params) {
        return $axios("/api/v1/stats", {
            method: "GET",
            params: params
        }).then(data => {
            return data;
        });
    },
    async getBw95Stats(params) {
        return $axios("/api/v1/bandwidth95/stats", {
            method: "GET",
            params: params
        }).then(data => {
            return data;
        });
    },
    async exportData(params) {
        return $axios("/api/v1/stats/excel", {
            method: "GET",
            params: params,
            responseType: "arraybuffer"
            // headers: {
            //     responseType: "arraybuffer"
            // }
        }).then(data => {
            return data;
        });
    },
};

export default stats;
