import instance from "./index";

const room = {
    async getRoom(params) {
        return instance("/api/v1/rooms", {
            method: "GET",
            params: params
        }).then(data => {
            return data;
        });
    },
    async getRoomId(params,id) {
        return instance(`/api/v1/rooms/${id}`, {
            method: "GET",
            params: params
        }).then(data => {
            return data;
        });
    },
    async createRoom(params) {
        return instance("/api/v1/rooms", {
            method: "POST",
            data: params
        }).then(data => {
            return data;
        });
    },
    async editRoom(params,id) {
        return instance(`/api/v1/rooms/${id}`, {
            method: 'PUT',
            data: params
        }).then(data => {
            return data
        });
    },
    async changeStatus(params,id) {
        return instance(`/api/v1/rooms/${id}/status`, {
            method: 'PUT',
            data: params
        }).then(data => {
            return data
        });
    },
    async getSelect(params) {
        return instance(`/api/v1/room/query_criteria`, {
            method: "GET",
            params: params
        }).then(data => {
            return data;
        });
    },
    async exportData(params) {
        return instance("/api/v1/stats/excel", {
            method: "POST",
            data: params,
            responseType: "arraybuffer"
        }).then(data => {
            return data;
        });
    },
    async getPrice(params, id) {
        return instance(`/api/v1/rooms/${id}/price_histories`, {
            method: "GET",
            params: params
        }).then(data => {
            return data;
        });
    },
    async createPrice(params, id) {
        return instance(`/api/v1/rooms/${id}/price`, {
            method: "POST",
            data: params
        }).then(data => {
            return data;
        });
    },
    async editPrice(params, priceID) {
        return instance(`/api/v1/rooms/price/${priceID}`, {
            method: 'PUT',
            data: params
        }).then(data => {
            return data
        });
    },
    async deletePrice(priceID) {
        return instance(`/api/v1/rooms/price/${priceID}`, {
            method: 'DELETE',
        }).then(data => {
            return data
        });
    },
};

export default room;
